import { Text, Stack, NumberInput, NumberInputField } from '@chakra-ui/react'
import { useState } from 'react'
import { useTransactionDeadline } from 'state/transactionDeadline'

export const TransactionDeadlineSetting = () => {
  const [deadline, setDeadline] = useTransactionDeadline()
  const [isInvalid, setIsInvalid] = useState<boolean>()
  const [_deadline, setDeadlineText] = useState<string | undefined>(
    deadline.toString()
  )

  const handleChange = (value: string) => {
    setIsInvalid(false)
    setDeadlineText(value)
    if (value) {
      setDeadline(parseInt(value))
    }
  }

  return (
    <>
      <Text mt={3} fontSize={{ base: 12, lg: 16 }}>
        Transaction Deadline
      </Text>
      <Stack direction="row" align="center" mt={3} spacing={2}>
        <NumberInput
          maxW="100px"
          mr="2rem"
          value={_deadline}
          onChange={handleChange}
          max={50}
          min={10}
          variant="ghost"
          size={{ base: 'sm', lg: 'lg' }}
          fontWeight={'bold'}
          onInvalid={() => setIsInvalid(true)}
          errorBorderColor="red.400"
        >
          <NumberInputField bg={'gray.700'} />
        </NumberInput>
        <Text mt={2} fontSize={{ base: 12, lg: 16 }}>
          Minutes
        </Text>
      </Stack>
      {isInvalid && (
        <Text mt={2} color="red.400">
          Minutes must be 10 ~ 50min
        </Text>
      )}
    </>
  )
}
