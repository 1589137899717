import { SettingsIcon } from '@chakra-ui/icons'
import { IconButton, useDisclosure } from '@chakra-ui/react'
import { SwapSettingModal } from './SettingModal'

export const SwapSettingIcon = () => {
  const { isOpen, onOpen, onClose } = useDisclosure()

  return (
    <>
      <IconButton icon={<SettingsIcon />} aria-label="Settings" onClick={onOpen} variant='ghost'/>
      <SwapSettingModal isOpen={isOpen} onClose={onClose} />
    </>
  )
}
