import { Keyring } from '@polkadot/api'
import type { InjectedAccountWithMeta } from '@polkadot/extension-inject/types'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useApi } from '../useApi'
import '@polkadot/api-augment'
import { Token } from '@/constants/tokens/types'
import PSP22 from '@/utils/types/contracts/psp22_token'
import BigNumber from 'bignumber.js'
import { toBigNumber } from '@/utils/token/formatBalance'
import { useBlockNumber } from 'state/blockNumber'

export interface Balance {
  address: string
  balance: BigNumber
  token: Token
}

export const useTokensBalance = (
  tokens: Token[],
  account?: InjectedAccountWithMeta | string
) => {
  const [balances, setBalances] = useState<Balance[]>([])
  const [blockNumber] = useBlockNumber()
  const api = useApi()
  const accountAddress = useMemo(() => {
    if (!account) {
      return
    }
    if (typeof account === 'string') {
      return account
    } else {
      return account.address
    }
  }, [account])

  const contracts = useMemo(() => {
    if (!api) {
      return null
    }
    if (!tokens || tokens?.length <= 0) {
      return null
    }
    const keyring = new Keyring({ type: 'sr25519' })
    const aliceKeyringPair = keyring.addFromUri('//Alice')
    return tokens.map((token) => {
      return {
        address: token.address,
        contract: new PSP22(token.address, aliceKeyringPair, api),
        token: token
      }
    })
  }, [tokens, api])

  const fetchBalance = useCallback(async () => {
    if (!contracts || !api || !accountAddress) {
      return
    }
    const balances = []

    for (let i = 0; i < contracts.length; i++) {
      const { address, contract, token } = contracts[i]
      if (token.isNative) {
        const { data: balance } = await api.query.system.account(accountAddress)
        balances.push({
          address,
          balance: toBigNumber(balance.free),
          token
        })
        continue
      }
      const { value } = await contract.query.balanceOf(accountAddress)
      const balance = value.ok!.rawNumber
      balances.push({
        address,
        balance: toBigNumber(balance),
        token
      })
    }
    setBalances(balances)
  }, [accountAddress, api, contracts])

  useEffect(() => {
    fetchBalance()
  }, [fetchBalance, blockNumber])

  return {
    balances,
    refetch: fetchBalance
  }
}
