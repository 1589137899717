import {
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  VStack
} from '@chakra-ui/react'
import { SlippageToleranceSetting } from './SlippageToleranceSetting'
import { TransactionDeadlineSetting } from './TransactionDeadlineSetting'

interface Prop {
  isOpen: boolean
  onClose: () => void
}

export const SwapSettingModal: React.FC<Prop> = ({ isOpen, onClose }) => {
  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent
          bg="gray.800"
          maxW={{ base: 320, lg: 480 }}
          mx={{ base: 2, lg: 'inherit' }}
        >
          <ModalCloseButton />
          <ModalBody>
            <VStack m={{ base: 1, lg: 5 }} mx={0} align={'left'}>
              <ModalBody fontSize={{ base: 14, lg: 16 }}>
                <Heading as="h2" fontSize={{ base: 16, lg: 20 }}>
                  Settings
                </Heading>
                <SlippageToleranceSetting />
                <TransactionDeadlineSetting />
              </ModalBody>
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}
