import { useEffect, useState } from 'react'
import { atom, useRecoilState } from 'recoil'
import { localStorageEffect } from './localStorageEffect'

const DEFAULT_SLIPPAGE = 0.01
const MIN_SLIPPAGE = 0.001
const MAX_SLIPPAGE = 0.50

export const currentSlippage = atom<number>({
  key: 'currentSlippage',
  default: DEFAULT_SLIPPAGE,
  effects: [
    localStorageEffect('currentSlippage')
  ]
})

type Result = [number, (num: number) => void]

export const useSlippage = (): Result => {
  const [didMount, setDidMount] = useState(false);
  const [slippage, setSlippageRaw] = useRecoilState(currentSlippage)
  const setSlippage = (num: number) => {
    if (num < MIN_SLIPPAGE) {
      throw 'INSUFFICIENT INPUT AMOUNT'
    }
    if (num > MAX_SLIPPAGE) {
      throw 'EXCEED MAXMIUM AMOUNT'
    }
    setSlippageRaw(num)
  }

  useEffect(() => {
    setDidMount(true)
  }, [])

  return [
    didMount ? slippage : DEFAULT_SLIPPAGE,
    setSlippage
  ]
}
