import { Box, Flex, Spacer, Text } from '@chakra-ui/react'
import BigNumber from 'bignumber.js'
import { FC, useMemo } from 'react'
import { ToolTip } from '@/components/Common/ToolTip'
import { Token } from '@/constants/tokens/types'
import { useLiquidityProviderFee } from '@/hooks/router/useLiquidityProviderFee'
import { TradeType } from '@/constants/tradeType'

interface Prop {
  outToken: Token
  inToken: Token
  minimumAmountReceived?: string
  maxAmountSold?: string
  amountIn?: BigNumber
  hop?: number
  priceImpact: BigNumber
  tradeType?: TradeType
}

export const Details: FC<Prop> = ({
  minimumAmountReceived,
  maxAmountSold,
  outToken,
  inToken,
  amountIn,
  hop,
  priceImpact,
  tradeType
}) => {
  const fee = useLiquidityProviderFee(amountIn, hop)
  const priceImpactColor = useMemo(() => {
    if (priceImpact.lte(2)) {
      return 'green'
    }
    if (priceImpact.lte(5)) {
      return 'yellow'
    }
    return 'red'
  }, [priceImpact])

  return (
    <Box
      borderRadius="12"
      background="blackAlpha.300"
      maxWidth="480px"
      minWidth={{ base: '100%', lg: '480px' }}
      px={5}
      py={3}
      fontSize={{ base: 12, lg: 16 }}
    >
      <Flex>
        {tradeType == TradeType.EXACT_INPUT && (
          <>
            <Text>
              Minimum Received
              <ToolTip
                text={
                  'Your transaction will revert if there is a large, unfavorable price movement before it is confirmed. '
                }
              />
            </Text>
            <Spacer />
            <Flex>
              {minimumAmountReceived} {outToken.symbol}
            </Flex>
          </>
        )}
        {tradeType == TradeType.EXACT_OUTPUT && (
          <>
            <Text>
              Maximum sold
              <ToolTip
                text={
                  'Your transaction will revert if there is a large, unfavorable price movement before it is confirmed.'
                }
              />
            </Text>
            <Spacer />
            <Flex>
              {maxAmountSold} {inToken.symbol}
            </Flex>
          </>
        )}
      </Flex>
      {priceImpact && (
        <Flex mt={2}>
          <Text>
            Price Impact
            <ToolTip
              text={
                'The difference between the market price and estimated price due to trade size'
              }
            />
          </Text>
          <Spacer />
          <Flex>
            <Text color={priceImpactColor}>
              {priceImpact.lte(0.01) ? '0.01<' : priceImpact.toFixed(3)} %
            </Text>
          </Flex>
        </Flex>
      )}
      {fee && (
        <Flex mt={2}>
          <Text>
            Liquidity Provider Fee
            <ToolTip
              text={
                'For each trade a 0.3 goes to liquidity providers and 0.05% goes to the eAPLO staking pool.'
              }
            />
          </Text>
          <Spacer />
          <Flex>
            {fee.toFixed(5)} {inToken.symbol}
          </Flex>
        </Flex>
      )}
    </Box>
  )
}
