import {
  ButtonGroup,
  NumberInput,
  NumberInputField,
  Stack,
  Text
} from '@chakra-ui/react'
import { useState } from 'react'
import { SlippageButton } from './SlippageButton'
import { useSlippage } from 'state/slippage'

export const SlippageToleranceSetting = () => {
  const [defaultValue, setSlippageGlobal] = useSlippage()
  const [isInvalid, setIsInvalid] = useState<boolean>()
  const [slippage, setSlippageInput] = useState<string | undefined>(
    (defaultValue * 100).toString()
  )

  const handleChange = (value: string | undefined) => {
    setIsInvalid(false)
    setSlippageInput(value)

    if (value) {
      setSlippageGlobal(parseFloat(value) / 100)
    }
  }

  return (
    <>
      <Text mt={3} fontSize={{ base: 12, lg: 14 }}>
        Slippage Tolerance
      </Text>
      <Stack direction="row" align="center" mt={3}>
        <ButtonGroup maxW={'60%'}>
          <SlippageButton
            _onClick={handleChange}
            val="0.1"
            slippage={slippage}
          />
          <SlippageButton
            _onClick={handleChange}
            val="0.5"
            slippage={slippage}
          />
          <SlippageButton _onClick={handleChange} val="1" slippage={slippage} />
        </ButtonGroup>
        <NumberInput
          maxW={{ base: '60px', lg: '100px' }}
          mr={{ base: '1rem', lg: '2rem' }}
          value={slippage}
          onChange={handleChange}
          max={50}
          min={0.1}
          variant="ghost"
          size={{ base: 'sm', lg: 'lg' }}
          fontWeight={'bold'}
          onInvalid={() => setIsInvalid(true)}
        >
          <NumberInputField fontSize={{ base: 14, lg: 16 }} bg={'gray.700'} />
        </NumberInput>
        <Text mt={2}>%</Text>
      </Stack>
      {isInvalid && (
        <Text mt={2} color="red.400" fontSize={{ base: 12, lg: 16 }}>
          Slippage Tolerance be 0.1 ~ 50%
        </Text>
      )}
    </>
  )
}
