import { QuestionOutlineIcon } from '@chakra-ui/icons'
import { Tooltip } from '@chakra-ui/react'
import React from 'react'

interface Prop {
  text: string
  ml?: number
}

export const ToolTip: React.FC<Prop> = ({ text, ml = 2 }) => {
  return (
    <Tooltip label={text} aria-label={text}>
      <QuestionOutlineIcon ml={ml} />
    </Tooltip>
  )
}
