import { useCallback, useEffect, useMemo, useState } from 'react'
import { Token } from '@/constants/tokens/types'
import { useBlockNumber } from 'state/blockNumber';
import { toBigNumber } from '@/utils/token/formatBalance'
import BigNumber from 'bignumber.js';
import { useApi } from '../useApi';
import { useAccounts } from '@/hooks/polkadotExtension/useAccounts'
import { usePsp22Contract } from '../contract/usePsp22Contract';

export const useTokenBalance = (token?: Token) => {
  const [balance, setBalance] = useState<BigNumber>()
  const [blockNumber] = useBlockNumber()
  const { account } = useAccounts()
  const contract = usePsp22Contract(token?.address, undefined)
  const api = useApi()

  const fetchBalance = useCallback(async () => {
    if (!contract || !api || !account || !token) { return }

    if (token.isNative) {
      const { data: balance } = await api.query.system.account(account.address)
      setBalance(toBigNumber(balance.free))
      return
    }
    const { value } = await contract.query.balanceOf(account.address)
    if (!value.ok) return
    const balance = value.ok.rawNumber
    setBalance(toBigNumber(balance))
  }, [account, api, contract, token])

  useEffect(() => {
    fetchBalance()
  }, [fetchBalance, blockNumber])

  return useMemo(() => ({
    balance
  }), [balance])
}
