import { Flex, HStack, Text } from '@chakra-ui/react'
import { TokenImage } from '@/components/Tokens/TokenImage'
import { Token } from '@/constants/tokens/types'

interface Prop {
  token: Token
}

export const tokenImage = (token: Token) => {
  return `tokens/${token.chainId}/${token.address}.png`
}

export const TokenLogo: React.FC<Prop> = ({ token }) => {
  return (
    <Flex>
      <HStack>
        <TokenImage address={`${token.address}`} boxSize={{ base: 5, lg: 6 }} />
        <Text fontSize={{ base: 14, lg: 16 }}>{token.symbol}</Text>
      </HStack>
    </Flex>
  )
}
