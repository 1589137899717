import {
Box,
Flex,
HStack,
Image,
Spacer,
Text
} from '@chakra-ui/react'
import { getDisplayBalanceAmount } from '@/utils/token/formatBalance'
import BigNumber from 'bignumber.js'

interface Token {
  address: string
  symbol: string
  name: string
  decimal: number
  chainId: number
}

interface Prop {
  token: Token
  balance?: BigNumber
  _onClick: (token?: Token) => void
}

export const TokenList: React.FC<Prop> = ({ token, _onClick, balance }) => {
  return (
    <>
      <Box
        as="button"
        cursor="pointer"
        _focus={{ bg: 'whiteAlpha.300' }}
        padding={2}
        rounded={8}
        w="100%"
        onClick={() => _onClick(token)}
        _hover={{ bg: 'whiteAlpha.100' }}
      >
        <Flex>
          <HStack spacing={2}>
            <Image
              src={`/tokens/${token.chainId}/${token.address}.png`}
              alt={token.name}
              boxSize={{ base: 6, lg: 8 }}
            />
            <Text>{token.symbol}</Text>
          </HStack>
          <Spacer />
          <HStack spacing={2}>
            <Text>{getDisplayBalanceAmount(balance, token.decimal)}</Text>
          </HStack>
        </Flex>
      </Box>
    </>
  )
}
