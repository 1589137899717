import { LIQUIDITY_PROVIDER_FEE } from '@/constants/liquidityProviderFee'

export const computeLiquidityProviderFee = (hop?: number) => {
  let fee = LIQUIDITY_PROVIDER_FEE
  if (hop && hop > 2) {
    fee = 1 - (1 - LIQUIDITY_PROVIDER_FEE) ** (hop - 1)
  }

  return fee
}
