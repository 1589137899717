import {
  Box,
  Button,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  InputGroup,
  NumberInput,
  NumberInputField,
  Spacer,
  useDisclosure
} from '@chakra-ui/react'
import { useEffect, useMemo } from 'react'
import { SelectTokenButton } from './SelectTokenButton'
import { SelectTokenModal } from './SelectTokenModal'
import { Token } from '@/constants/tokens/types'
import { useAccounts } from '@/hooks/polkadotExtension/useAccounts'
import { useNativeToken } from '@/hooks/token/useNativeToken'
import { useAllTokens } from '@/hooks/token/useTokens'
import { useTokensBalance } from '@/hooks/token/useTokensBalance'
import { getDisplayBalanceAmount } from '@/utils/token/formatBalance'
import { useFetchTokenUSDCPrice } from '@/hooks/token/useFetchTokenUSDCPrice'
import BigNumber from 'bignumber.js'
import { useGetWrappedTokenIfNative } from '@/hooks/token/useGetWrappedTokenIfNative'
import { TransactionState } from '@/constants/transaction'

interface SelectTokenProp {
  title: string
  showMax: boolean
  setNativeTokenAsDefault?: boolean
  inputAmount: string
  setInputAmount: (inputAmount: string) => void
  setToken: (token: Token) => void
  token?: Token
  excludeToken?: Token
  transactionState?: TransactionState
}

export const SelectToken: React.FC<SelectTokenProp> = ({
  title,
  showMax,
  setNativeTokenAsDefault,
  inputAmount,
  token,
  setInputAmount,
  setToken,
  excludeToken,
  transactionState
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure()
  const { account } = useAccounts()
  const nativeToken = useNativeToken()
  const allTokens = useAllTokens()
  const wrappedExcludeToken = useGetWrappedTokenIfNative(excludeToken)
  const tokens = useMemo(() => {
    return allTokens
      .filter((token) => !token.isWrapped)
      .filter((token) => token.address != excludeToken?.address)
      .filter((token) => token.address != wrappedExcludeToken?.address)
  }, [allTokens, excludeToken?.address, wrappedExcludeToken?.address])
  const { balances, refetch } = useTokensBalance(tokens || [], account)
  const USDC = useMemo(() => {
    return tokens.find((token) => token.symbol === 'USDC')
  }, [tokens])

  const tokenPrice = useFetchTokenUSDCPrice(token)

  const tokenValueInUSD = useMemo(() => {
    if (tokenPrice && tokenPrice.price && inputAmount) {
      const isTokenUSDC = token?.symbol === USDC?.symbol

      return new BigNumber(isTokenUSDC ? '1' : tokenPrice.price).multipliedBy(
        new BigNumber(inputAmount)
      )
    }
  }, [tokenPrice, inputAmount, token, USDC])

  const balance = useMemo(() => {
    return balances?.find((balance) => balance.address == token?.address)
      ?.balance
  }, [token, balances])

  const fullBalance = useMemo(() => {
    return getDisplayBalanceAmount(balance, token?.decimal)
  }, [balance, token])

  useEffect(() => {
    if (!token && nativeToken && setNativeTokenAsDefault) {
      setToken(nativeToken)
    }
  }, [nativeToken, setNativeTokenAsDefault, token])

  useEffect(() => {
    if (transactionState && transactionState === TransactionState.SUCCESS) {
      refetch()
    }
  }, [refetch, transactionState])

  return (
    <>
      <Box backgroundColor="blackAlpha.300" padding="12px" borderRadius="8px">
        <FormControl>
          <Flex align="center">
            <FormLabel fontSize={{ base: 14, lg: 16 }}>{title}</FormLabel>
            <Spacer />
            {showMax && (
              <Button
                size={{ base: 'sm', lg: 'md' }}
                fontSize={{ base: 14, lg: 16 }}
                bg="blackAlpha.300"
                border="1px"
                rounded="md"
                borderColor="whiteAlpha.500"
                onClick={() => setInputAmount(fullBalance)}
              >
                Max
              </Button>
            )}
          </Flex>
          <InputGroup mt="4" mb="4">
            <SelectTokenButton token={token} _onClick={onOpen} />
            <NumberInput
              width="100%"
              fontSize={{ base: 14, lg: 16 }}
              size={{ base: 'sm', lg: 'lg' }}
              value={inputAmount}
              onChange={setInputAmount}
            >
              <NumberInputField
                border="0"
                placeholder="0.0"
                textAlign={'right'}
              />
            </NumberInput>
          </InputGroup>
          <Flex justify="space-between">
            <FormHelperText fontSize={{ base: 12, lg: 16 }}>
              Balance: {fullBalance}
            </FormHelperText>
            {tokenValueInUSD && (
              <FormHelperText fontSize={{ base: 12, lg: 16 }}>
                $ {tokenValueInUSD.toFixed(2)}
              </FormHelperText>
            )}
          </Flex>
        </FormControl>
      </Box>
      <SelectTokenModal
        isOpen={isOpen}
        onClose={onClose}
        selectToken={setToken}
        tokens={tokens}
        balances={balances}
      />
    </>
  )
}
