import { Text } from '@chakra-ui/react'
import React from 'react'
import { useSlippage } from 'state/slippage'

export const SlippageTolerance = () => {
  const [slippage] = useSlippage()
  return (
    <Text fontSize={{ base: 12, lg: 14 }}>
      Slippage Tolerance: {(slippage * 100).toFixed(2)}%
    </Text>
  )
}
