import { ArrowDownIcon } from '@chakra-ui/icons'
import { IconButton, IconButtonProps } from '@chakra-ui/react'

interface Prop extends IconButtonProps {
  onHandleClick: () => void
}

export const SwitchTokenButton: React.FC<Prop> = ({
  onHandleClick,
  ...rest
}) => {
  return (
    <IconButton
      margin="-25px"
      icon={<ArrowDownIcon />}
      pos={'relative'}
      bg={'gray.800'}
      zIndex={10}
      _hover={{ bg: 'gray.700' }}
      width={{ base: '25px', lg: '50px' }}
      height={{ base: '38px', lg: '50px' }}
      borderRadius={'50%'}
      onClick={onHandleClick}
      {...rest}
    />
  )
}
