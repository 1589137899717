import { Button } from '@chakra-ui/react'
import useTranslation from 'next-translate/useTranslation'
import { Token } from '@/constants/tokens/types'
import { ApprovalState } from '@/hooks/token/useApproveCallback'

interface SwapButtonProperty {
  token: Token
  approveState: ApprovalState
  swap: () => void
  approve: () => void
}

export const SwapButton: React.FC<SwapButtonProperty> = ({
  token,
  approveState,
  swap,
  approve
}) => {
  const { t } = useTranslation('common')
  if (token.isNative || approveState === ApprovalState.APPROVED) {
    return (
      <Button
        fontSize={{ base: 14, lg: 16 }}
        onClick={swap}
        fontWeight={'bold'}
      >
        {t('Swap')}
      </Button>
    )
  }
  if (approveState === ApprovalState.NOT_APPROVED) {
    return (
      <Button
        fontSize={{ base: 14, lg: 16 }}
        onClick={approve}
        fontWeight={'bold'}
      >
        {t(`Approve ${token.symbol}`)}
      </Button>
    )
  }

  if (approveState === ApprovalState.UNKNOWN) {
    return (
      <Button fontSize={{ base: 14, lg: 16 }} isDisabled>
        fetching....
      </Button>
    )
  }

  return null
}
