import {
  Button,
  Heading,
  Icon,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
  VStack
} from '@chakra-ui/react'
import { AiFillCloseCircle } from 'react-icons/ai'
import { TransactionState } from '@/constants/transaction'

interface Prop {
  txState?: TransactionState
  setTxState: (state?: TransactionState) => void
  error: string
}

export const TxErrorModal: React.FC<Prop> = ({
  txState,
  setTxState,
  error
}) => {
  return (
    <>
      <Modal
        isOpen={txState === TransactionState.ERROR}
        onClose={() => {
          setTxState()
        }}
      >
        <ModalOverlay />
        <ModalContent p={10}>
          <ModalCloseButton />
          <ModalBody>
            <VStack spacing={5} m={5}>
              <Icon as={AiFillCloseCircle} boxSize={20} />
              <Heading as="h2" size="md">
                Transaction Failed
              </Heading>
              <Text>{error}</Text>
              <Button
                colorScheme="whiteAlpha.600"
                variant="outline"
                mt={5}
                mr={3}
                onClick={() => {
                  setTxState()
                }}
              >
                Close
              </Button>
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}
