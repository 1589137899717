import { SearchIcon } from '@chakra-ui/icons'
import {
  Box,
  Divider,
  Input,
  InputGroup,
  InputLeftElement,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Text
} from '@chakra-ui/react'
import useTranslation from 'next-translate/useTranslation'
import { useMemo, useState } from 'react'
import { TokenList } from '../Swap/TokenList'
import { Token } from '@/constants/tokens/types'
import { Balance } from '@/hooks/token/useTokensBalance'

interface Prop {
  isOpen: boolean
  tokens: Token[]
  balances: Balance[]
  onClose: () => void
  selectToken: (token: Token) => void
}

export const SelectTokenModal: React.FC<Prop> = ({
  isOpen,
  onClose,
  selectToken,
  balances,
  tokens
}) => {
  const { t } = useTranslation('common')
  const [search, setSearchName] = useState<string>()
  const filteredTokens = useMemo(() => {
    if (!search || search === '') return tokens
    return tokens.filter((token) =>
      token.name.toLowerCase().includes(search.toLowerCase())
    )
  }, [search, tokens])

  return (
    <>
      <Modal blockScrollOnMount={false} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent bg="gray.700" mx={{ base: 4, lg: 'inherit' }}>
          <ModalHeader fontSize={{ base: 16, lg: 20 }}>
            {t('Select a Token')}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <InputGroup>
              <InputLeftElement pointerEvents="none">
                <SearchIcon color="gray.300" />
              </InputLeftElement>
              <Input
                fontSize={{ base: 14, lg: 16 }}
                type="text"
                placeholder={t('Search name or paste the token address')}
                onChange={(event) => setSearchName(event.target.value)}
                value={search}
              />
            </InputGroup>
            <Text my={3} fontSize={{ base: 14, lg: 16 }}>
              {t('Token name')}
            </Text>
            <Divider />
            <Box overflowY="scroll" maxH={400} fontSize={{ base: 14, lg: 16 }}>
              {balances &&
                filteredTokens?.map((token, i) => {
                  const balance = balances.find(
                    (b) => b.address == token.address
                  )?.balance
                  return (
                    <div key={i}>
                      <Spacer mt={1} />
                      <TokenList
                        token={token}
                        balance={balance}
                        _onClick={() => {
                          selectToken(token)
                          onClose()
                        }}
                      />
                    </div>
                  )
                })}
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  )
}
