import { Button } from '@chakra-ui/react'

interface ButtonProp {
  _onClick: (arg: string) => void
  val: string
  slippage?: string
}

export const SlippageButton: React.FC<ButtonProp> = ({
  _onClick,
  slippage,
  val
}) => {
  return (
    <Button
      _hover={{ bg: 'gray.600' }}
      _active={{ bg: 'gray.600' }}
      variant="ghost"
      onClick={() => {
        _onClick(val)
      }}
      isActive={slippage == val}
      size={{ base: 'sm', lg: 'lg' }}
      fontWeight={'bold'}
    >
      {val}%
    </Button>
  )
}
