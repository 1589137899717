import { Box, Flex, Spacer, Text } from '@chakra-ui/react'
import { FC } from 'react'
import { TokenImage } from '@/components/Tokens/TokenImage'
import { useChainId } from '@/hooks/useApi'

interface Prop {
  routePath?: string[]
}

export const RoutePath: FC<Prop> = ({ routePath }) => {
  const chainId = useChainId()
  return (
    <Box
      borderRadius="12"
      background="blackAlpha.300"
      maxWidth="480px"
      minWidth={{ base: '100%', lg: '480px' }}
      px={5}
      py={3}
      fontSize={{ base: 12, lg: 16 }}
    >
      <Flex>
        <Text>Route</Text>
        <Spacer />
        <Flex>
          {routePath?.map((path, i) => (
            <TokenImage address={path} key={i} ml={2} boxSize={'20px'} />
          ))}
        </Flex>
      </Flex>
    </Box>
  )
}
