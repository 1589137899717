import { Button } from '@chakra-ui/button'
import { ChevronDownIcon } from '@chakra-ui/icons'
import { HStack, Spacer, Text } from '@chakra-ui/react'
import { TokenLogo } from './TokenLogo'
import { Token } from '@/constants/tokens/types'

interface Prop {
  token?: Token | null
  _onClick: () => void
}

export const SelectTokenButton: React.FC<Prop> = ({ token, _onClick }) => {
  return (
    <Button
      onClick={_onClick}
      size={{ base: 'sm', lg: 'lg' }}
      px={2}
      bg="whiteAlpha.200"
      _hover={{
        bg: 'whiteAlpha.200'
      }}
      _active={{
        bg: 'whiteAlpha.200'
      }}
    >
      <HStack spacing={{ base: 3, lg: 6 }}>
        {token ? (
          <TokenLogo token={token} />
        ) : (
          <Text ml={3} mr={-4}>
            Select
          </Text>
        )}
        <Spacer />
        <ChevronDownIcon />
      </HStack>
    </Button>
  )
}
