import BigNumber from 'bignumber.js'
import { useMemo } from 'react'
import { useIdealPriceOfTokenIn } from './useIdealPriceOfTokenIn'
import { Token } from '@/constants/tokens/types'
import { computeLiquidityProviderFee } from '@/utils/swap/computeLiquidityProviderFee'

interface Params {
  bestTradingPath?: string[]
  inputAmount?: BigNumber
  amountOut?: BigNumber
  tokenIn?: Token
  tokenOut?: Token
}

export const usePriceImpact = ({
  bestTradingPath,
  inputAmount,
  amountOut,
  tokenIn,
  tokenOut
}: Params) => {
  const idealPrice = useIdealPriceOfTokenIn(bestTradingPath, tokenIn, tokenOut)
  const lpFee = computeLiquidityProviderFee(bestTradingPath?.length)

  return useMemo(() => {
    // priceImpact = (idealAmountOut - actualAmountOut) / idealAmountOut - lpFee
    if (!amountOut || !inputAmount) { return }
    const idealAmountOut = idealPrice?.multipliedBy(inputAmount)
    const deltaAmountOut = (idealAmountOut?.minus(amountOut))?.div(idealAmountOut)
    const priceImpact = (deltaAmountOut?.minus(lpFee))?.multipliedBy(100)
    return priceImpact
  }, [amountOut, inputAmount, idealPrice, lpFee])
}
