
// @ts-ignore
    import __i18nConfig from '@next-translate-root/i18n'
// @ts-ignore
    import __loadNamespaces from 'next-translate/loadNamespaces'
// @ts-ignore
    
import { NextPage } from 'next/types'
import { Layout } from '@/components/Layout'
import { useRouter } from 'next/router'
import { useFindTokenByAddressOrSymbol } from '@/hooks/token/useFindTokenByAddressOrSymbol'
import { Swap } from '@/components/Swap'

const SwapPage: NextPage = () => {
  const router = useRouter()
  const { slug } = router.query
  const [tokenAddress, quotedTokenAddress] = slug
    ? (slug as string[])
    : [undefined, undefined]
  const token = useFindTokenByAddressOrSymbol(tokenAddress)
  const quotedToken = useFindTokenByAddressOrSymbol(quotedTokenAddress)

  return (
    <Layout>
      <Swap token={token} quotedToken={quotedToken} />
    </Layout>
  )
}
export default SwapPage


// @ts-ignore
    export async function getServerSideProps(ctx) {
// @ts-ignore
        
// @ts-ignore
        
// @ts-ignore
        return {
// @ts-ignore
          
// @ts-ignore
          
// @ts-ignore
          props: {
// @ts-ignore
            
// @ts-ignore
            ...(await __loadNamespaces({
// @ts-ignore
              ...ctx,
// @ts-ignore
              pathname: '/swap/[[...slug]]',
// @ts-ignore
              loaderName: 'getServerSideProps',
// @ts-ignore
              ...__i18nConfig,
// @ts-ignore
              loadLocaleFrom: (l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default),
// @ts-ignore
            }))
// @ts-ignore
          }
// @ts-ignore
        }
// @ts-ignore
    }
// @ts-ignore
  