import {
  Box,
  Button,
  Container,
  Divider,
  Flex,
  Heading,
  Spacer,
  Stack
} from '@chakra-ui/react'
import useTranslation from 'next-translate/useTranslation'
import { useEffect, useMemo, useState } from 'react'
import { TxSuccessModal } from '../Modal/TxnSuccessModal'
import { WaitForConfirmationModal } from '../Modal/WaitForConfirmationModal'
import { SelectToken } from '../Tokens/SelectToken'
import { SwapButton } from '../Tokens/SwapButton'
import { RoutePath } from './RoutePath'
import { SwitchTokenButton } from './SwitchTokenButton'
import { Details } from '@/components/Swap/Details'
import { SlippageTolerance, SwapSettingIcon } from '@/components/SwapSetting'
import { Token } from '@/constants/tokens/types'
import { useBestTrade } from '@/hooks/graph/useBestTrade'
import { useBuildGraph } from '@/hooks/graph/useBuildGraph'
import { usePriceImpact } from '@/hooks/router/usePriceImpact'
import { useSwap } from '@/hooks/router/useSwap'
import { useApproveCallback } from '@/hooks/token/useApproveCallback'
import { useToBigNumber } from '@/hooks/utils/useToBigNumber'
import { useSlippage } from 'state/slippage'
import { toggleTradeType, TradeType } from '@/constants/tradeType'
import { useRouterContract } from '@/hooks/contract/useRouterContract'
import { useTokenBalance } from '@/hooks/token/useTokenBalance'
import { getDecimalAmount } from '@/utils/token/formatBalance'
import BigNumber from 'bignumber.js'
import { TxErrorModal } from '../Modal/TxnErrorModal'

interface SwapProps {
  token?: Token
  quotedToken?: Token
}

interface TokenWithAmount {
  token?: Token
  amount: string
}

export const Swap: React.FC<SwapProps> = ({ token, quotedToken }) => {
  const { t } = useTranslation('common')
  const graph = useBuildGraph()
  const [tokenA, setTokenA] = useState<TokenWithAmount>({
    token: undefined,
    amount: ''
  })
  const [tokenB, setTokenB] = useState<TokenWithAmount>({
    token: undefined,
    amount: ''
  })
  const [slippage] = useSlippage()
  const amountA = useToBigNumber(tokenA?.amount)
  const amountB = useToBigNumber(tokenB?.amount)
  const { balance: currentTokenAbalance } = useTokenBalance(tokenA?.token)

  const router = useRouterContract()
  const [tradeType, setTradeType] = useState<TradeType>()

  useEffect(() => {
    if (token) {
      setTokenA((prev) => ({ ...prev, token: token }))
    }
    if (quotedToken) {
      setTokenB((prev) => ({ ...prev, token: quotedToken }))
    }
  }, [token, quotedToken])

  const amountOutMin = useMemo(
    () =>
      tradeType == TradeType.EXACT_INPUT
        ? amountB.multipliedBy(1 - slippage)
        : amountB,
    [slippage, amountB, tradeType]
  )
  const amountInMax = useMemo(
    () =>
      tradeType == TradeType.EXACT_OUTPUT
        ? amountA.multipliedBy(1 + slippage)
        : amountA,
    [slippage, amountA, tradeType]
  )

  const { bestTradePath, amountOut, amountIn } = useBestTrade({
    tokenA: tokenA?.token,
    tokenB: tokenB?.token,
    amountIn: amountA,
    amountOut: amountB,
    graph,
    tradeType: tradeType
  })

  const { swapState, swap, setSwapState, txResult, error: errorSwap } = useSwap(
    tokenA?.token,
    tokenB?.token,
    amountInMax,
    amountOutMin,
    bestTradePath,
    tradeType
  )

  const [
    TOKEN_A_APPROVAL_STATE,
    approveTokenA,
    approvalTxnAState,
    approvalTxnAResult,
    setApprovalTxnA,
    errorApproval
  ] = useApproveCallback(tokenA?.token, amountA, router?.address)

  const priceImpact = usePriceImpact({
    bestTradingPath: bestTradePath,
    inputAmount: amountA,
    amountOut,
    tokenIn: tokenA?.token,
    tokenOut: tokenB?.token
  })

  const isSufficientAmount = useMemo(() => {
    if (!tokenA?.token || !currentTokenAbalance || !amountA) {
      return false
    }
    return currentTokenAbalance?.gte(
      getDecimalAmount(amountA, tokenA.token.decimal) as BigNumber
    )
  }, [tokenA, currentTokenAbalance, amountA])

  const isPriceImpactTooHigh = useMemo(
    () => priceImpact?.gt(slippage * 100),
    [priceImpact, slippage]
  )

  useEffect(() => {
    if (amountOut?.gt(0) && tradeType == TradeType.EXACT_INPUT) {
      setTokenB((prev) => ({ ...prev, amount: amountOut.toString() }))
    }
  }, [amountOut, tradeType])

  useEffect(() => {
    if (amountIn?.gt(0) && tradeType == TradeType.EXACT_OUTPUT) {
      setTokenA((prev) => ({ ...prev, amount: amountIn.toString() }))
    }
  }, [amountIn, tradeType])

  const onSwitchToken = () => {
    setTokenA(tokenB)
    setTokenB(tokenA)
    setTradeType(toggleTradeType(tradeType))
  }

  return (
    <>
      <Flex direction={'column'}>
        <Box
          borderRadius="12"
          background="blackAlpha.300"
          maxWidth="480px"
          minWidth={{ base: '100%', lg: '480px' }}
          p={{ base: 1, lg: '5' }}
          py={4}
        >
          <WaitForConfirmationModal
            txState={swapState || approvalTxnAState}
            setTxState={swapState ? setSwapState : setApprovalTxnA}
            blockHash={txResult?.blockHash || approvalTxnAResult?.blockHash}
          />
          <TxSuccessModal
            txState={swapState || approvalTxnAState}
            setTxState={swapState ? setSwapState : setApprovalTxnA}
            blockHash={txResult?.blockHash || approvalTxnAResult?.blockHash}
          />
          <TxErrorModal
            txState={swapState || approvalTxnAState}
            setTxState={swapState ? setSwapState : setApprovalTxnA}
            error={errorSwap || errorApproval}
          />
          <Container>
            <Flex>
              <Heading as="h1" fontSize={{ base: 16, lg: 24 }} mb="5">
                {t('Swap')}
              </Heading>
              <Spacer />
              <SwapSettingIcon />
            </Flex>
            <Stack spacing="2">
              <SelectToken
                title="From"
                showMax={true}
                setNativeTokenAsDefault={true}
                token={tokenA?.token}
                excludeToken={tokenB.token}
                setToken={(token) => setTokenA((prev) => ({ ...prev, token }))}
                inputAmount={tokenA?.amount}
                setInputAmount={(amount) => {
                  setTokenA((prev) => ({ ...prev, amount }))
                  setTradeType(TradeType.EXACT_INPUT)
                }}
                transactionState={swapState}
              />
              <Flex className="hoge" justifyContent="center">
                <SwitchTokenButton
                  onHandleClick={onSwitchToken}
                  m={{ base: '-12px', lg: '-25px' }}
                  aria-label=""
                />
              </Flex>
              <SelectToken
                title="To"
                token={tokenB.token}
                excludeToken={tokenA.token}
                setToken={(token) => setTokenB((prev) => ({ ...prev, token }))}
                inputAmount={tokenB?.amount}
                setInputAmount={(amount) => {
                  setTokenB((prev) => ({ ...prev, amount }))
                  setTradeType(TradeType.EXACT_OUTPUT)
                }}
                showMax={false}
                transactionState={swapState}
              />
            </Stack>
            <Spacer mt={3} />
            <SlippageTolerance />
            <Stack mt="5">
              {!isSufficientAmount && (
                <Button isDisabled fontSize={{ base: 14, lg: 16 }}>
                  Insufficient {tokenA?.token?.symbol} balance
                </Button>
              )}

              {isSufficientAmount && isPriceImpactTooHigh && (
                <Button
                  isDisabled
                  colorScheme={'red'}
                  fontSize={{ base: 14, lg: 16 }}
                >
                  Price Impact Too High
                </Button>
              )}

              {(tokenA.token?.isNative || TOKEN_A_APPROVAL_STATE) &&
                tokenA?.token &&
                isSufficientAmount &&
                !isPriceImpactTooHigh && (
                  <SwapButton
                    token={tokenA.token}
                    approveState={TOKEN_A_APPROVAL_STATE}
                    swap={swap}
                    approve={approveTokenA}
                  />
                )}
            </Stack>
          </Container>
        </Box>
        <Box mt={5} mb={2}>
          {tokenB?.token &&
            amountB &&
            tokenA?.token &&
            amountA &&
            priceImpact &&
            amountInMax && (
              <Details
                minimumAmountReceived={amountOutMin.toFixed(5)}
                maxAmountSold={amountInMax.toFixed(5)}
                outToken={tokenB.token}
                inToken={tokenA.token}
                amountIn={amountA}
                hop={bestTradePath?.length}
                priceImpact={priceImpact}
                tradeType={tradeType}
              />
            )}
        </Box>
        {bestTradePath && (
          <>
            <Divider />
            <Box mt={2}>
              <RoutePath routePath={bestTradePath} />
            </Box>
          </>
        )}
      </Flex>
    </>
  )
}
