import BigNumber from 'bignumber.js'
import { useCallback, useEffect, useState } from 'react'
import { usePsp22Contract } from '../contract/usePsp22Contract'
import { Token } from '@/constants/tokens/types'

export const useTokenAllowance = (token?: Token, owner?: string, spender?: string) => {
  const psp22 = usePsp22Contract(token?.address, null)
  const [allowance, setAllowance] = useState<BigNumber>()

  const fetchTokenAllowance = useCallback(async () => {
    if (!psp22 || !owner || !spender) {
      return
    }
    const { value } = await psp22.query.allowance(owner, spender)
    if (!value.ok) return
    const balance = new BigNumber(value.ok.toString())
    setAllowance(balance)
  }, [owner, psp22, spender])

  useEffect(() => {
    fetchTokenAllowance()
  }, [psp22, owner, spender, token, fetchTokenAllowance])

  return {
    allowance,
    fetchTokenAllowance
  }

}

export default useTokenAllowance
