import { useMemo } from 'react'
import { useAllTokens } from './useTokens'

export const useFindTokenByAddressOrSymbol = (addressOrSymbol?: string) => {
  const tokens = useAllTokens()
  return useMemo(() => {
    // fetch Token's infor by tokenAddress if it doesn't exsit in constant
    return (
      tokens.find((x) => x.address == addressOrSymbol || x.symbol == addressOrSymbol)
    )
  }, [addressOrSymbol, tokens])
}
