import { atom, useRecoilState } from 'recoil'
import { localStorageEffect } from './localStorageEffect'

const DEFAULT_DEADLINE = 20

export const currentTransactionDeadline = atom<number>({
  key: 'currentTransactionDeadline',
  default: DEFAULT_DEADLINE,
  effects: [localStorageEffect('currentTransactionDeadline')],
})

type Result = [number, (num: number) => void]

export const useTransactionDeadline = (): Result => {
  const [deadline, setDeadline] = useRecoilState(currentTransactionDeadline)

  return [
    deadline,
    setDeadline
  ]
}
