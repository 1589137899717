import type { InjectedAccountWithMeta, InjectedExtension } from '@polkadot/extension-inject/types'
import { SignAndSendSuccessResponse } from '@arthswap/typechain-types'
import BigNumber from 'bignumber.js'
import { useToast } from '@chakra-ui/react'
import { useCallback, useState } from 'react'
import { useRouterContract } from '../contract/useRouterContract'
import { useAccounts } from '../polkadotExtension/useAccounts'
import { useGetWrappedTokenIfNative } from '../token/useGetWrappedTokenIfNative'
import { Token } from '@/constants/tokens/types'
import { getDisplayDecimalAmount } from '@/utils/token/formatBalance'
import Router from '@/utils/types/contracts/router_contract'
import { useTransactionDeadline } from 'state/transactionDeadline'
import { TransactionState } from '@/constants/transaction'
import { TradeType } from '@/constants/tradeType'
import { sentryLogError } from '@/utils/env/sentry'

const unixTime = () => Math.floor(Date.now())

interface SwapFunctioncCallArgs {
  tokenA: Token
  tokenB: Token
  amountIn: BigNumber
  amountOut: BigNumber
  router: Router
  account: InjectedAccountWithMeta
  signer: InjectedExtension
  routePath: string[]
  transactionDeadline: number
  queryErrorCb: (error: string) => void
}

const computeDeadlineFromNow = (deadline: number) => {
  const currentTime = unixTime()
  const DEADLINE_MIN = deadline * 60 * 1000
  return currentTime + DEADLINE_MIN
}

const swapExactTokensForTokens = async ({
  tokenA,
  tokenB,
  amountIn,
  amountOut,
  signer,
  router,
  account,
  routePath,
  transactionDeadline,
  queryErrorCb
}: SwapFunctioncCallArgs) => {
  // here isWrapped is used to indicate it is native token of the network
  const deadline = computeDeadlineFromNow(transactionDeadline)

  // ETH -> Tokens swap: tokenA(native) and tokenb(psp22)
  if (tokenA.isWrapped) {
    const { value, gasRequired, storageDeposit } =
      await router.query.swapExactNativeForTokens(
        getDisplayDecimalAmount(amountOut, tokenB.decimal), // amount_out_min: Balance,
        routePath,
        account.address,
        deadline,
        { value: getDisplayDecimalAmount(amountIn, tokenA.decimal) }
      )

    if (value.ok?.err) {
      const error = Object.keys(value.ok?.err as Object)[0]

      queryErrorCb(error)
      sentryLogError(error)
      return
    }

    const tx = await router.tx.swapExactNativeForTokens(
      getDisplayDecimalAmount(amountOut, tokenB.decimal),
      routePath,
      account.address,
      deadline,
      {
        value: getDisplayDecimalAmount(amountIn, tokenA.decimal),
        gasLimit: gasRequired,
        storageDepositLimit: storageDeposit.asCharge
      },
      { signer: signer.signer }
    )
    return tx
  }

  let swapFunctionName:
    | 'swapExactTokensForTokens'
    | 'swapExactTokensForNative'
    | undefined

  // tokens -> Eth Swap: tokenA (psp22) and tokenB (native)
  if (tokenB.isWrapped) {
    swapFunctionName = 'swapExactTokensForNative'
  } else {
    // tokens -> tokens swap: tokenA (psp22) and tokenB (psp22)
    swapFunctionName = 'swapExactTokensForTokens'
  }

  if (!swapFunctionName) {
    throw 'NO SWAP FUNCTION IS FOUND'
  }

  const { value, gasRequired, storageDeposit } = await router.query[
    swapFunctionName
  ](
    getDisplayDecimalAmount(amountIn, tokenA.decimal),
    getDisplayDecimalAmount(amountOut, tokenB.decimal),
    routePath,
    account.address,
    deadline
  )


  if (value.ok?.err) {
    const error = Object.keys(value.ok?.err as Object)[0]

    queryErrorCb(error)
    sentryLogError(error)
    return
  }

  const tx = await router.tx[swapFunctionName](
    getDisplayDecimalAmount(amountIn, tokenA.decimal), // amount_amount_in
    getDisplayDecimalAmount(amountOut, tokenB.decimal), // amount_out_min: Balance,
    routePath,
    account.address,
    deadline,
    { gasLimit: gasRequired, storageDepositLimit: storageDeposit.asCharge },
    { signer: signer.signer }
  )
  return tx
}

const swapTokensForExactTokens = async ({
  tokenA,
  tokenB,
  amountIn,
  amountOut,
  signer,
  router,
  account,
  routePath,
  transactionDeadline
}: SwapFunctioncCallArgs) => {
  // here isWrapped is used to indicate it is native token of the network
  const deadline = computeDeadlineFromNow(transactionDeadline)

  // ETH -> Tokens swap: tokenA(native) and tokenb(psp22)
  if (tokenA.isWrapped) {
    const { value, gasRequired, storageDeposit } =
      await router.query.swapNativeForExactTokens(
        getDisplayDecimalAmount(amountOut, tokenB.decimal), // amount_out: Balance,
        routePath,
        account.address,
        deadline,
        { value: getDisplayDecimalAmount(amountIn, tokenA.decimal) }
      )

    const tx = await router.tx.swapNativeForExactTokens(
      getDisplayDecimalAmount(amountOut, tokenB.decimal), // amount_out: Balance,
      routePath,
      account.address,
      deadline,
      {
        value: getDisplayDecimalAmount(amountIn, tokenA.decimal),
        gasLimit: gasRequired,
        storageDepositLimit: storageDeposit.asCharge
      },
      { signer: signer.signer }
    )
    return tx
  }

  let swapFunctionName:
    | 'swapTokensForExactTokens'
    | 'swapTokensForExactNative'
    | undefined

  // tokens -> Eth Swap: tokenA (psp22) and tokenB (native)
  if (tokenB.isWrapped) {
    swapFunctionName = 'swapTokensForExactNative'
  } else {
    // tokens -> tokens swap: tokenA (psp22) and tokenB (psp22)
    swapFunctionName = 'swapTokensForExactTokens'
  }

  if (!swapFunctionName) {
    throw 'NO SWAP FUNCTION IS FOUND'
  }

  const { value, gasRequired, storageDeposit } = await router.query[
    swapFunctionName
  ](
    getDisplayDecimalAmount(amountIn, tokenA.decimal),
    getDisplayDecimalAmount(amountOut, tokenB.decimal),
    routePath,
    account.address,
    deadline
  )


  const tx = await router.tx[swapFunctionName](
    getDisplayDecimalAmount(amountIn, tokenA.decimal), // amount_amount_in
    getDisplayDecimalAmount(amountOut, tokenB.decimal), // amount_out_min: Balance,
    routePath,
    account.address,
    deadline,
    { gasLimit: gasRequired, storageDepositLimit: storageDeposit.asCharge },
    { signer: signer.signer }
  )
  return tx
}

export const useSwap = (
  tokenA?: Token,
  tokenB?: Token,
  inputAmount?: BigNumber,
  outputAmount?: BigNumber,
  routePath?: string[],
  tradeType?: TradeType
) => {
  const tokenAorWrapped = useGetWrappedTokenIfNative(tokenA)
  const tokenBorWrapped = useGetWrappedTokenIfNative(tokenB)
  const [transactionDeadline] = useTransactionDeadline()
  const [swapState, setSwapState] = useState<TransactionState>()
  const [txResult, setTxResult] = useState<SignAndSendSuccessResponse>()
  const [error, setError] = useState('')
  const { account, signer } = useAccounts()
  const router = useRouterContract()
  const toast = useToast()

  const queryErrorCb = useCallback((error: string) => {
    setSwapState(TransactionState.ERROR)
    setError(error)
  }, [])


  const swap = async () => {
    if (
      !tokenAorWrapped ||
      !tokenBorWrapped ||
      !inputAmount ||
      !outputAmount ||
      !router ||
      !signer ||
      !routePath ||
      !account
    ) {
      return null
    }
    setError('')
    setSwapState(TransactionState.WAITING_FOR_CONFIRMATION)
    switch (tradeType as TradeType) {
      case TradeType.EXACT_INPUT:
        await swapExactTokensForTokens({
          tokenA: tokenAorWrapped,
          tokenB: tokenBorWrapped,
          amountIn: inputAmount,
          amountOut: outputAmount,
          router,
          signer,
          account,
          routePath,
          transactionDeadline,
          queryErrorCb
        })
          .then((tx) => {
            if (!tx) {
              return
            }

            tx.wait.then(() => {
              setTxResult(tx)
              if (tx.error) {
                setError(tx.error.message)
                setSwapState(TransactionState.ERROR)
                toast({
                  title: 'Swap failed',
                  description: error,
                  status: 'error',
                  duration: 5000,
                  isClosable: true
                })
                sentryLogError(tx.error)
                return
              }
              setSwapState(TransactionState.SUCCESS)

            })
          })
          .catch((e) => {
            setError(e.error?.message)
            setSwapState(TransactionState.ERROR)
            toast({
              title: 'Swap failed',
              description: e.message,
              status: 'error',
              duration: 5000,
              isClosable: true
            })
            sentryLogError(e)
            console.error('hi error occured', e)
          })
        break
      case TradeType.EXACT_OUTPUT:
        await swapTokensForExactTokens({
          tokenA: tokenAorWrapped,
          tokenB: tokenBorWrapped,
          amountIn: inputAmount,
          amountOut: outputAmount,
          router,
          signer,
          account,
          routePath,
          transactionDeadline,
          queryErrorCb
        })
          .then((tx) => {

            tx.wait.then(() => {
              setTxResult(tx)
              if (tx.error) {
                setError(tx.error.message)
                setSwapState(TransactionState.ERROR)
                sentryLogError(tx.error)
                return
              }

              setSwapState(TransactionState.SUCCESS)

            })
          })
          .catch((e) => {
            setError(e.error?.message)
            setSwapState(TransactionState.ERROR)
            sentryLogError(e)
            console.error('hi error occured', e)
          })
        break
    }
  }

  return {
    swapState,
    txResult,
    setSwapState,
    swap,
    error
  }
}
