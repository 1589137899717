import { SignAndSendSuccessResponse } from '@arthswap/typechain-types'
import BigNumber from 'bignumber.js'
import {
useCallback,
useMemo,
useState,
Dispatch,
SetStateAction
} from 'react'
import { usePsp22Contract } from '../contract/usePsp22Contract'
import { useAccounts } from '../polkadotExtension/useAccounts'
import { useTokenAllowance } from './useTokenAllowance'
import { Token } from '@/constants/tokens/types'
import { TransactionState } from '@/constants/transaction'
import { MaxUint128 } from '@/constants/numbers'
import { getDisplayDecimalAmount } from '@/utils/token/formatBalance'
import { sentryLogError } from '@/utils/env/sentry'

export enum ApprovalState {
  UNKNOWN,
  NOT_APPROVED,
  APPROVED
}

// returns a variable indicating the state of the approval and a function which approves if necessary or early returns
export const useApproveCallback = (
  token?: Token,
  amount?: BigNumber,
  spender?: string
): [
  ApprovalState,
  () => Promise<void>,
  TransactionState | undefined,
  SignAndSendSuccessResponse | undefined,
  Dispatch<SetStateAction<TransactionState | undefined>>,
  string
] => {
  const [approveTxnState, setApproveTxnState] = useState<TransactionState>()
  const [approveResult, setApproveResult] =
    useState<SignAndSendSuccessResponse>()
  const [error, setError] = useState('')
  const { account, keyringPair, signer } = useAccounts()
  const { allowance: currentAllowance, fetchTokenAllowance } =
    useTokenAllowance(token, account?.address, spender)
  const psp22 = usePsp22Contract(token?.address, keyringPair)
  // check the current approval status
  const approvalState: ApprovalState = useMemo(() => {
    if (!amount || !spender || !token) return ApprovalState.UNKNOWN
    // we might not have enough data to know whether or not we need to approve
    if (!currentAllowance) return ApprovalState.UNKNOWN

    const formattedAmount = BigNumber(
      getDisplayDecimalAmount(amount, token.decimal)
    )

    // amountToApprove will be defined if currentAllowance is
    return currentAllowance.gte(formattedAmount) && !currentAllowance.isZero()
      ? ApprovalState.APPROVED
      : ApprovalState.NOT_APPROVED
  }, [amount, currentAllowance, spender, token])

  const approve = useCallback(async (): Promise<void> => {
    if (approvalState !== ApprovalState.NOT_APPROVED) {
      console.error('approve was called unnecessarily')
      return
    }
    if (!token) {
      console.error('no token')
      return
    }

    if (!spender) {
      console.error('no spender')
      return
    }
    if (!psp22) {
      return
    }

    let amountToApprove = MaxUint128

    const { gasRequired, storageDeposit } = await psp22.query.approve(
      spender,
      amountToApprove.toFixed(0)
    )
    setError('')
    setApproveTxnState(TransactionState.WAITING_FOR_CONFIRMATION)
    await psp22.tx
      .approve(
        spender,
        amountToApprove.toFixed(0),
        { gasLimit: gasRequired, storageDepositLimit: storageDeposit.asCharge },
        { signer: signer?.signer }
      )
      .then((tx) => {
        tx.wait.then(() => {
          setApproveResult(tx)
          if (tx.error) {
            setError(tx.error.message)
            setApproveTxnState(TransactionState.ERROR)
            sentryLogError(tx.error)
            return
          }

          setApproveTxnState(TransactionState.SUCCESS)

        })
      })
      .catch((e) => {
        setError(e.error?.message)
        setApproveTxnState(TransactionState.ERROR)
        sentryLogError(e)
        console.error('hi error occured', e)
      })
    fetchTokenAllowance()
  }, [approvalState, token, spender, psp22, signer?.signer, fetchTokenAllowance])

  return [
    approvalState,
    approve,
    approveTxnState,
    approveResult,
    setApproveTxnState,
    error
  ]
}
