import BigNumber from 'bignumber.js'
import { useMemo } from 'react'

export const useToBigNumber = (amount: string) => {
  return useMemo(() => {
    if (!amount) {
      return new BigNumber(0)
    }
    return new BigNumber(amount)
  }, [amount])
}
